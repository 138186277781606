 .btn_style:hover {
   font-size: 15px;
   color: blue;
   font-weight: 100;
}

.btn_sider:hover {
  color: blue;
}

.ant-menu-item-selected{
  color: rgb(36 37 38);
}

.btn_fund_cluster {
  border: 0px;
}

.ant-table-row-collapsed:after {
content: "\25BE";
    font-size: 17px;
 font-weight: 500;
}

.ant-table-row-expand-icon{
    border: 0px;
 font-size: 17px;
}

.ant-table-row-expanded:after{
    content: "\25B4";
    font-size: 17px;
 font-weight: 500;
}

.navigatorAccount::after {
  color: "black";
}
